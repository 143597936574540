<div *ngIf="transactionDetail">
  <div class="main-container is-support-widescreen main-transaction-viewer">
    <form [formGroup]="transactionInfoForm">
      <div class="row">
        <div class="col-sm-6 col-xl-8 image-viewer-section" id="image-viewer-section">
          <transaction-guest-info [isSamePageNavigation]="true" [guestData]="memberDetail" [transactionData]="transactionDetail"> </transaction-guest-info>
          <!-- <ng-container *ngIf="!isRpt; else rptTmpl"> -->
          <c-receipt-image-viewer [isRpt]="isRpt" [transactionDetail]="transactionDetail"></c-receipt-image-viewer>
          <!-- </ng-container>
          <ng-template #rptTmpl>
            <RPT-no-img></RPT-no-img>
          </ng-template> -->
        </div>
        <div class="col-sm-6 col-xl-4">
          <div class="transaction-info-section">
            <!-- <h3>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_INFO' | translate }} ({{ transactionDetail?.id }})</h3> -->
            <div class="row" style="margin-bottom: 15px; display: flex; align-items: center; justify-content: center">
              <div class="col-7">
                <h3 style="margin: 0" class="d-flex align-items-center">
                  {{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_INFO' | translate }} ({{ transactionDetail?.id }})
                  <RPT-icon [ocrTransactionDetail]="transactionDetail" [isRpt]="isRpt"></RPT-icon>
                </h3>
              </div>
              <div class="col-5">
                <transaction-ocr-status-badge [isRpt]="isRpt" [transactionDetail]="transactionDetail" [ocrRetCode]="transactionDetail.ocrRetCode"> </transaction-ocr-status-badge>
              </div>
            </div>
            <div class="pt-6">
              <div style="margin-bottom: 20px">
                <receipt-transaction-associated-member-picker [dataSource]="associatedMemberDataSource" [selectedIndex]="isCurrentlyCreditToSelf ? 0 : 1" [disabled]="true"> </receipt-transaction-associated-member-picker>
              </div>
            </div>

            <div class="ocr-label-wrapper">
              <span class="label">{{ 'RECEIPT_TRANSACTIONS.LABEL.TENANT_NAME' | translate }} </span>
              <span class="ocr-label" [ngClass]="{ fuzzy: !ocrLabelVisibilityMapping.get('ocrTenantId') }" *ngIf="transactionDetail.ocrTenantId">OCR: {{ transactionDetail.ocrTenantId | merchant | async }}</span>
              <span class="ocr-label ocr-label-red" *ngIf="isDisplayTenantIdOriginalLabel">Original: {{ transactionDetail.originalTenantId | merchant | async }}</span>
            </div>
            <mat-form-field class="full-width">
              <!-- <mat-label class="ocr-label-wrapper">
                <span class="label">{{ 'RECEIPT_TRANSACTIONS.LABEL.TENANT_NAME' | translate }} </span>
                <span class="ocr-label" *ngIf="transactionDetail.originalTenantId">Original: {{ transactionDetail.originalTenantId | merchant | async }}</span>
              </mat-label> -->
              <input matInput disabled [value]="transactionDetail?.tenantId | merchant | async" />
            </mat-form-field>

            <div class="ocr-label-wrapper">
              <span class="label">{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE' | translate }} </span>
              <div class="ocr-label-tap" *ngIf="transactionDetail.ocrSalesReceiptTransactionDate || transactionDetail?.ocrElectronicReceiptTransactionDate">
                <p class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('ocrSalesReceiptTransactionDateGreenColor') }" *ngIf="ocrLabelVisibilityMapping.get('ocrSalesReceiptTransactionDate')">
                  OCR (Merchant): {{ transactionDetail.ocrSalesReceiptTransactionDate | date : 'dd-MM-yyyy' }}
                </p>
                <p class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('ocrElectronicTransactionDateGreenColor') }" *ngIf="transactionDetail?.ocrElectronicReceiptTransactionDate">
                  OCR (Electronic): {{ transactionDetail?.ocrElectronicReceiptTransactionDate | date : 'dd-MM-yyyy' }}
                </p>
              </div>
              <span class="ocr-label ocr-label-red" *ngIf="isDisplayTransactionDateOriginalLabel">Original: {{ transactionDetail.originalTransactionDate | systemDateFormat }}</span>
            </div>
            <mat-form-field class="full-width">
              <!-- <mat-label class="ocr-label-wrapper">
                <span class="label">{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE' | translate }} </span>
                <span class="ocr-label" *ngIf="transactionDetail.originalTransactionDate">Original: {{ transactionDetail.originalTransactionDate | systemDateFormat }}</span>
              </mat-label> -->
              <input matInput disabled [value]="transactionDetail?.transactionDate | systemDateFormat" />
            </mat-form-field>

            <div class="ocr-label-wrapper">
              <span class="label" translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_TIME</span>
              <div class="ocr-label-tap" *ngIf="transactionDetail.ocrSalesReceiptTransactionDate || transactionDetail.ocrElectronicReceiptTransactionDate">
                <p class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('ocrTransactionTimeGreenColor') }" *ngIf="ocrLabelVisibilityMapping.get('ocrTransactionTime')">
                  OCR (Merchant): {{ transactionDetail.ocrSalesReceiptTransactionDate | date : 'HH:mm' }}
                </p>
                <p class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('ocrElectronicTimeGreenColor') }" *ngIf="transactionDetail.ocrElectronicReceiptTransactionDate">
                  OCR (Electronic) : {{ transactionDetail.ocrElectronicReceiptTransactionDate | date : 'HH:mm' }}
                </p>
              </div>
            </div>
            <mat-form-field class="full-width">
              <input matInput disabled [value]="transactionDetail?.transactionTime | date : 'HH:mm'" />
            </mat-form-field>

            <div class="ocr-label-wrapper">
              <span class="label" translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT</span>
              <div class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('ocrSalesReceiptAmountGreenColor') }" *ngIf="ocrLabelVisibilityMapping.get('ocrSalesReceiptAmount')">
                OCR: {{ transactionDetail.ocrSalesReceiptAmount | systemCurrency }}
              </div>
            </div>
            <mat-form-field class="full-width">
              <div class="d-flex"> <input matInput disabled [value]="transactionDetail?.transactionAmount == 'N/A'? 'N/A': (transactionDetail?.transactionAmount | systemCurrency)" /></div>
              <mat-hint
                class="reminder reminder-Rpt"
                *ngIf="!['SPLIT_PAYMENT_TRANSACTION', 'CONSIGNMENT_CHILD_TRANSACTION'].includes(transactionDetail?.type) && transactionDetail?.pointEarned == 'PROCESSED'"
                align="start"
              >
                <div class="d-flex align-items-center">
                  <span style="color: red">Point Earned: {{ transactionDetail?.totalPointsEarned | number }} </span
                  ><mat-icon style="color: #333; cursor: pointer" class="RPTinfo-icon mx-2" id="isPointEarnformula"> info </mat-icon>
                </div>
                <dx-popover target="#isPointEarnformula" hideEvent="mouseleave" showEvent="click" position="top" [width]="400">
                  <h4 style="color: #204568; margin-bottom: 8px">Remarks</h4>
                  <div style="color: #888c92">
                    Point Earn = [Transaction Amount] - [Excluded Amount] - [Actualized Mall Dollar / eVoucher Amount] - [Payment amount for ineligible to earn point Payment Method]

                    <p style="margin-top: 10px">* must greater than minimum spending amount</p>
                  </div>
                </dx-popover>
              </mat-hint>
            </mat-form-field>

            <!-- Excluded Amount -->
            <div *ngIf="featureFlagService.getFeatureFlag('RPT') && transactionDetail?.rptReceipt">
              <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                <mat-label>Excluded Amount from Payment Terminal</mat-label>
                <span style="color: #888">HKD&nbsp;</span>
                <calculation-input disabled="true" [placeholder]="placeholder" formControlName="rptExcludedAmount"></calculation-input>
              </mat-form-field>

              <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                <mat-label>Actualized Mall Dollar / eVoucher amount</mat-label>
                <span style="color: #888">HKD&nbsp;</span>
                <calculation-input disabled="true" [placeholder]="placeholder" formControlName="actualizedAmount"></calculation-input>
              </mat-form-field>
            </div>

            <div class="ocr-label-wrapper">
              <span class="label" translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT</span>
              <span [ngClass]="{ fuzzy: ocrReceiptAmountFuzzyMatch }" class="ocr-label" *ngIf="ocrLabelVisibilityMapping.get('ocrElectronicReceiptAmount')"
                >OCR: {{ transactionDetail.ocrElectronicReceiptAmount | systemCurrency }}</span
              >
              <span class="ocr-label ocr-label-red" *ngIf="isDisplayTransactionAmountOriginalLabel">Original: {{ transactionDetail.originalTransactionAmount | systemCurrency }}</span>
            </div>
            <mat-form-field class="full-width">
              <!-- <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT</mat-label> -->
              <input matInput disabled [value]="transactionDetail?.receiptAmount | systemCurrency" />
            </mat-form-field>

            <div class="ocr-label-wrapper">
              <span class="label" translate>RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD</span>
              <!-- <span class="ocr-label" [ngClass]="{ fuzzy: ocrLabelVisibilityMapping.get('paymentMethodGreenColor') }" *ngIf="ocrLabelVisibilityMapping.get('ocrPaymentMethodValue')"
                >OCR: {{ ocrLabelVisibilityMapping.get('ocrPaymentMethodValue') }}</span
              > -->
              <span class="ocr-label" [ngClass]="{ fuzzy: transactionDetail?.ocrPaymentMethodMatch }" *ngIf="transactionDetail?.paymentMethod && !ViewTransactionConstants.noDispalyOcrPaymentMethod.includes(transactionDetail?.paymentMethod)">
                {{ transactionDetail?.paymentMethod | genericCategory : 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async}} （OCR: {{ transactionDetail?.ocrPaymentMethodMatch ? 'PASS' : 'FAIL' }}）
              </span>
            </div>
            <!-- <mat-form-field class="full-width">
              <input matInput disabled [value]="transactionDetail?.paymentMethod | genericCategory : 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async" />
            </mat-form-field> -->
            <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
              <ng-select formControlName="paymentMethod" ngSelectMat>
                <ng-option [disabled]="item.disabled" *ngFor="let item of dropdownData?.RECEIPT_TRANSACTION.PAYMENT_METHOD" bindLabel="label" [value]="item.value">
                  {{ item.label }}
                </ng-option>
              </ng-select>
            </mat-form-field>

            <div class="ocr-label-wrapper">
              <span requiredInput [isRequired]="isInvoiceNumbersRequired" [invalidInput]="transactionInfoForm.get('invoiceNumbers').invalid && transactionInfoForm.get('invoiceNumbers').touched" class="label" translate
                >RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER</span
              >
              <div *ngIf="ocrLabelVisibilityMapping.get('ocrInvoiceNumber') && getBusinessUnit == 'CP'">
                <div class="ocr-label ocr-label-invoice-number" [ngClass]="{ fuzzy: isOCRFuzzy }">
                  <i *ngIf="transactionDetail.ocrInvoiceNumber.length > 1">OCR: </i>
                  <p *ngFor="let item of transactionDetail.ocrInvoiceNumber; let idx = index"><i *ngIf="transactionDetail.ocrInvoiceNumber.length == 1">OCR: </i> {{ item }}</p>
                </div>
              </div>
              <span class="ocr-label" [ngClass]="{ fuzzy: isOCRFuzzy }" *ngIf="ocrLabelVisibilityMapping.get('ocrInvoiceNumber') && getBusinessUnit != 'CP'">OCR: {{ transactionDetail.ocrInvoiceNumber }}</span>

              <span class="ocr-label ocr-label-red" *ngIf="transactionDetail.duplicateInvoiceOverride">Duplicated</span>
            </div>
            <mat-form-field class="full-width">
              <!-- [value]="transactionDetail?.invoiceNumbers || []" -->
              <app-chip-input formControlName="invoiceNumbers" [readonly]="!(isEdit && transactionDetail.rptReceipt)"> </app-chip-input>
            </mat-form-field>

            <!-- RPT id -->
            <ng-container *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <div class="ocr-label-wrapper">
                <span
                  class="label"
                  [invalidInput]="transactionInfoForm.get('rptAcquireReferenceNos').invalid && transactionInfoForm.get('rptAcquireReferenceNos').touched"
                  translate
                  >RECEIPT_TRANSACTIONS.LABEL.RPT_ACQUIRE_REF_NO</span
                >

                <span class="ocr-label" [ngClass]="{ fuzzy: transactionDetail?.ocrRptAcquireReferenceNoFuzzyMatch }" *ngIf="transactionDetail.ocrRptAcquireReferenceNo?.length"
                  >OCR: {{ transactionDetail.ocrRptAcquireReferenceNo }}</span
                >
                <span class="ocr-label ocr-label-red" *ngIf="transactionDetail.isAcquireReferenceNoDuplicated">Duplicated</span>
              </div>
              <mat-form-field class="full-width" floatLabel="never">
                <app-chip-input formControlName="rptAcquireReferenceNos" [readonly]="true"></app-chip-input>
              </mat-form-field>
            </ng-container>

            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_REMARKS</mat-label>
              <!-- <input matInput formControlName="remarks" /> -->
              <textarea rows="5" matInput formControlName="remarks"></textarea>
            </mat-form-field>

            <mat-checkbox [appCanAccess]="'ui#loyalty#transaction#depositTransaction'" color="primary" class="deposit-transaction" formControlName="depositTransaction">{{
              'RECEIPT_TRANSACTIONS.LABEL.DEPOSIT_TRANSACTION' | translate
            }}</mat-checkbox>
            <div class="deposit-transaction-type" [class.hidden]="!depositTransaction.value || linkDepositTransactionId.value?.length > 0 || !haveDepositTransactionAccess">
              <div class="instruction mb-3 mt-2">Please Select Deposit Type</div>
              <mat-radio-group formControlName="depositType">
                <mat-radio-button [disableRipple]="true" class="full-width mb-2" color="primary" value="FULL">
                  <div>
                    <div>{{ 'RECEIPT_TRANSACTIONS.LABEL.FULLY_PAID_DEPOSIT' | translate }}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button [disableRipple]="true" class="full-width" color="primary" value="PARTIAL">
                  <div>
                    <div>{{ 'RECEIPT_TRANSACTIONS.LABEL.PARTIALLY_PAID_DEPOSIT' | translate }}</div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="miniReminder">
              <span class="ocr-remark"> Uploaded by {{transactionDetail?.rptReceipt ? (transactionDetail?.tenantId | merchant | async) :transactionDetail.createdBy}} on {{ transactionDetail.createdDate | systemDateTimeFormat }} </span>
              <span class="ocr-remark" *ngIf="transactionDetail.ocrProcessedDate">
                Last OCR processing date & time: {{ transactionDetail.ocrProcessedDate | systemDateTimeFormat }}, Ref Code: {{ transactionDetail.ocrRetCode }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- RPT -->
      <div class="mt-3" *ngIf="featureFlagService.getFeatureFlag('RPT')">
        <RPT-transaction [data]="transactionDetail"></RPT-transaction>
      </div>

      <div class="main-container" [appCanAccess]="'ui#loyalty#transaction#depositTransaction'">
        <related-deposit-transaction
          [linkedPickupSlipTransactionId]="transactionDetail?.id"
          [guestId]="transactionDetail.guestId"
          [readonly]="!isAllowAttachmentChange"
          [tenantId]="this.transactionInfoForm.get('tenantId').value"
          formControlName="linkDepositTransactionId"
        ></related-deposit-transaction>
      </div>
    </form>
  </div>
  <div class="main-container mb-5" *ngIf="hasWorkflowAuditSection">
    <!-- Attachment List -->
    <workflow-attachments-list
      [isFixHeight]="false"
      #workFlowAttachmentList
      [isReceiptRelated]="true"
      [uploadedFileList]="attachments"
      [isAllowAttachmentChange]="isAllowAttachmentChange"
      (attachments)="attachmentOnChange($event)"
      [isDisableUploadBtn]="isDisableUploadBtn"
    ></workflow-attachments-list>
    <receipt-transaction-approval-history [approvalHistoryList]="approvalHistoryList"> </receipt-transaction-approval-history>
  </div>
  <!-- TODO: IS STICKY-->
  <div [class.form-stick-bottom-bar]="hasStickyThumbnail" [class.view-transaction-stick-bottom-bar]="hasStickyThumbnail">
    <!-- * Image thumbnail -->
    <div class="card-container row no-gutter full-width" [class.bottom-receipt-image-thumbnail]="hasStickyThumbnail && isAllowSubmitVoidRequest && (isShowVoidReceiptSection || isShowEditReceiptSection)">
      <div class="col-4 pl-0">
        <span class="upload-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.TENANT_RECEIPT</span>
        <span class="upload-type-title" translate>&nbsp; ({{ transactionDetail.salesReceipts.length }})</span>
        <c-receipt-image-list [isRpt]="isRpt" [isBottomThumbnail]="true" [images]="transactionDetail.salesReceipts"> </c-receipt-image-list>
      </div>
      <div class="col-4 pl-0">
        <span class="upload-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_RECEIPT</span>
        <span class="upload-type-title" translate>&nbsp; ({{ transactionDetail.electronicReceipts.length }})</span>
        <c-receipt-image-list [isRpt]="isRpt" [isBottomThumbnail]="true" [images]="transactionDetail.electronicReceipts"> </c-receipt-image-list>
      </div>
      <div class="col-4 pl-0">
        <span class="upload-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.MEMBER_IDENTITY</span>
        <span class="upload-type-title" translate>&nbsp; ({{ transactionDetail.memberIdentity.length }})</span>
        <c-receipt-image-list [isRpt]="isRpt" [isBottomThumbnail]="true" [images]="transactionDetail.memberIdentity"> </c-receipt-image-list>
      </div>
    </div>
    <div class="d-flex full-width justify-content-between" *ngIf="isShowEditReceiptSection || (isAllowSubmitVoidRequest && isShowVoidReceiptSection)">
      <div class="bottom-action-btn d-flex align-items-center justify-content-start left-button-container">
        <ng-container *ngIf="isShowEditReceiptSection && isShowEditButton">
          <span *ngIf="isEdit">Save Changes?</span>
          <c-form-button [isLoading]="isSubmitting" *ngIf="isEdit" buttonType="stroke" [class]="'icon-button check ml-2'" [upperCaseLabel]="true" icon="check" (click)="onClickSaveReceipt()"> </c-form-button>
          <c-form-button *ngIf="isEdit" buttonType="stroke" [class]="'icon-button'" [upperCaseLabel]="true" icon="clear" (click)="onClickCancelEditReceipt()"></c-form-button>

          <c-form-button *ngIf="!isEdit" [upperCaseLabel]="true" class="edit-cancel-button" color="basic" label="Edit" (click)="onClickEditReceipt()"></c-form-button>
        </ng-container>
      </div>
      <div class="bottom-action-btn d-flex justify-content-end right-button-container" *ngIf="isAllowSubmitVoidRequest && isShowVoidReceiptSection">
        <ng-container>
          <!-- <c-form-button *ngIf="!isEdit" [upperCaseLabel]="true" class="edit-cancel-button" color="basic" label="Edit" (click)="onClickEditReceipt()"></c-form-button>
          <c-form-button *ngIf="isEdit" [upperCaseLabel]="true" class="edit-cancel-button" color="basic" label="Cancel" (click)="onClickCancelEditReceipt()"></c-form-button>
          <c-form-button [isLoading]="isSubmitting" *ngIf="isEdit" label="Save Changes" [upperCaseLabel]="true" (click)="onClickSaveReceipt()"></c-form-button> -->
          <c-form-button buttonType="stroke" label="Void Receipt" [upperCaseLabel]="true" (click)="onClickVoidReceipt()"></c-form-button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #transactionButtonTemplate>
  <c-form-button *ngIf="!isEdit" buttonType="stroke" [class]="'icon-button'" [upperCaseLabel]="true" icon="edit_outlined" (click)="onClickEditReceipt()"></c-form-button>
  <c-form-button [isLoading]="isSubmitting" *ngIf="isEdit" buttonType="stroke" [class]="'icon-button check ml-2'" [upperCaseLabel]="true" icon="check" (click)="onClickSaveReceipt()"></c-form-button>
  <c-form-button *ngIf="isEdit" buttonType="stroke" [class]="'icon-button ml-2'" [upperCaseLabel]="true" icon="clear" (click)="onClickCancelEditReceipt()"></c-form-button>
</ng-template>
