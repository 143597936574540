import { Component, Input, OnInit} from '@angular/core';
import { TransactionConstants } from '../../receipt-transaction.constant';


@Component({
  selector: 'RPT-transaction',
  templateUrl: './RPT-transaction.component.html',
  styleUrls: ['./RPT-transaction.component.scss'],
})
export class RPTTransactionComponent implements OnInit {
  @Input() data: any = null;
  // @Input() relatedActualizationTransaction: any[] = [];
  TransactionConstants = TransactionConstants;
  relatedActualizationTransaction: any[] = [];
  dataSouce: any[] = [];
  isRPT = false;
  constructor() {}
  ngOnInit(): void {
    this.isRPT = this.data?.rptReceipt;
    this.dataSouce = this.data?.relatedSplitPaymentConsignmentTransaction ?? [];
    const relatedActualizationTransaction = this.data?.relatedActualizationTransaction ?? [];
    if(['SPLIT_PAYMENT_TRANSACTION','CONSIGNMENT_MAIN_TRANSACTION'].includes(this.data?.type) || ['PPAPP','TKPAPP','HK1CRM'].includes(this.data?.transactionUploadSource) ) {
        this.relatedActualizationTransaction = [];
    }else {
       this.relatedActualizationTransaction = relatedActualizationTransaction;
    }
  }
}
