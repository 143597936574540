<div mat-dialog-content>
  <div translate>{{featureFlagService.getFeatureFlag('RPT')?'RECEIPT_TRANSACTIONS.MESSAGE.DUPLICATE_INVOICE': (customData.message | translate)}}</div>
  <!-- <div translate>{{ customData.message | translate }}</div> -->
  <div class="duplicate-records-grid">
    <div *ngFor="let transaction of customData.duplicatedTransactionList" class="transaction-row">
      <div class="row justify-content-between">
        <div class="col-9">
          <span *ngIf="transaction?.receiptTransactionInvoiceNumbers?.length > 0; else rpt" [ngClass]="{'transaction-id': featureFlagService.getFeatureFlag('RPT')}"
            >{{ 'COMMON.LABEL.TRANSACTION' | translate }} {{ transaction.id }} ( Invoice number :
            {{ transaction.receiptTransactionInvoiceNumbers.length > 0 ? transaction.receiptTransactionInvoiceNumbers[0].invoiceNumber : '' }} )</span
          >
          <!-- isRpt #1d7afc-->
          <ng-template #rpt>
            <span class="transaction-blue"
              >{{ 'COMMON.LABEL.TRANSACTION' | translate }} {{ transaction.id }} ( RPT Acquirer Ref No :
              {{ transaction.receiptTransactionAcquireReferenceNos.length > 0 ? transaction.receiptTransactionAcquireReferenceNos[0].acquireReferenceNo : '' }} )</span
            >
          </ng-template>
        </div>
        <div class="col-3 text-right">
          <span class="badge transaction-link label-uppercase" (click)="openCompareView(transaction.id)">
            {{ 'RECEIPT_TRANSACTIONS.LABEL.CHECK_AND_COMPARE' | translate }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 item-label">{{ 'RECEIPT_TRANSACTIONS.LABEL.TENANT' | translate }}</div>
        <div class="col-6">{{ transaction.tenantId | merchant | async }}</div>
      </div>
      <div class="row">
        <div class="col-6 item-label">{{ 'RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT' | translate }}</div>
        <div class="col-6">{{ transaction.receiptAmount | systemCurrency }}</div>
      </div>
      <div class="row">
        <div class="col-6 item-label d-flex align-items-center">Status</div>
        <div class="col-6">
          <share-transaction-status class="duplicate-records-status" [status]="transaction.status"></share-transaction-status>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="action-wrapper has-sticky-action">
  <c-form-button color="basic" class="grey" label="COMMON.LABEL.CANCEL" (click)="data.noCallback(dialogRef)" [upperCaseLabel]="true"></c-form-button>
  <c-form-button color="primary" [label]="data.yesLabel" (click)="data.yesCallback(dialogRef)" [upperCaseLabel]="true"></c-form-button>
</div>
