<ng-container>
<ng-container *ngIf="isRPT">
  <h3 class="sub-section">Related Split Payment / Consignment Transaction</h3>
  <div class="my-3 rpt-transaction-table">
    <dx-data-grid #dataGrid appDatagridSort [wordWrapEnabled]="true" [dataSource]="dataSouce"
      [remoteOperations]="{ paging: true }" [hoverStateEnabled]="true" [allowColumnResizing]="true"
      [columnAutoWidth]="true" [noDataText]="'There is no deposit record yet'">
      <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false">
      </dxo-remote-operations>
      <!-- <dxo-scrolling [useNative]="true"></dxo-scrolling> -->
      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxi-column [width]="140" dataField="status" caption="Status" cellTemplate="statusCell"></dxi-column>
      <div *dxTemplate="let cellData of 'statusCell'" class="cell-icon-row">
        <share-transaction-status [status]="cellData.value"></share-transaction-status>
        <svg-icon *ngIf="TransactionConstants.splitPaymentTransaction.includes(cellData.value)" style="margin-left: 10px;"
          src="/assets/images/icons/transactions/split-payment.svg" alt=""></svg-icon>
        <svg-icon
          *ngIf="TransactionConstants.consignmentTransaction.includes(cellData.value)"
          style="margin-left: 10px;" src="/assets/images/icons/transactions/consignment.svg" alt=""></svg-icon>
      </div>
      <dxi-column [width]="110" dataField="id" caption="Transaction ID" cellTemplate="linkCell"></dxi-column>
      <div *dxTemplate="let data of 'linkCell'">
        <a target="_blank" [routerLink]="['/admin/receipt-transaction/view/', data.data.id]">{{ data.value }}</a>
      </div>
      <dxi-column dataField="acquireReferenceNos" width="120" caption="RPT Acquirer Ref No"></dxi-column>
      <dxi-column dataField="transactionAmount" width="160" caption="Transaction Amount"
        cellTemplate="transactionCell"></dxi-column>
      <dxi-column dataField="receiptAmount" width="180" caption="Electronic Receipt Amount"
        cellTemplate="electronicCell"></dxi-column>
      <dxi-column dataField="transactionUploadSource" caption="Upload Channel"></dxi-column>
      <dxi-column dataField="createdBy" width="200" caption="Created By (Email / Member ID)"></dxi-column>
      <div *dxTemplate="let cellData of 'transactionCell'">
        <span *ngIf="cellData.value"> {{['SPLIT_PAYMENT','VOIDED_SPLIT_PAYMENT'].includes(cellData.data.status)?'-': cellData.value | systemCurrency }} </span>
      </div>
      <div *dxTemplate="let cellData of 'electronicCell'">
        <span *ngIf="cellData.value"> {{ cellData.value | systemCurrency }} </span>
      </div>
      <div *dxTemplate="let cellData of 'pointEarnedCell'">
        {{ cellData.value | number | replaceEmptyValue }}
      </div>
    </dx-data-grid>
  </div>
</ng-container>
  <!-- Related Actualize Record -->
  <h3 class="sub-section">Related Actualize Record</h3>
  <div class="my-3 rpt-transaction-table">
    <dx-data-grid #dataGrid appDatagridSort [wordWrapEnabled]="true" [dataSource]="relatedActualizationTransaction"
      [remoteOperations]="{ paging: true }" [hoverStateEnabled]="true" [allowColumnResizing]="true"
      [columnAutoWidth]="true" [noDataText]="'There is no deposit record yet'">
      <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false">
      </dxo-remote-operations>
      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxi-column dataField="transactionNo" caption="Actualization Txn Number"></dxi-column>
      <dxi-column dataField="transactionDate" caption="Actualization Date" dataType="date"
        cellTemplate="dateCell"></dxi-column>
      <div *dxTemplate="let cellData of 'dateCell'">
        {{ cellData.value | systemDateTimeFormat }}
      </div>
      <dxi-column dataField="giftName" caption="Gift Name"></dxi-column>
      <dxi-column cellTemplate="electronicCell" dataField="transactionAmount" caption="Actualized Amount"></dxi-column>
      <div *dxTemplate="let cellData of 'electronicCell'">
        <span> {{ cellData.value | systemCurrency }} </span>
      </div>
    </dx-data-grid>
  </div>
</ng-container>