import { DialogData } from '../models/application/dialog-data';
import { DialogService } from '@core/services/application/dialog';
import { CarParkMiddlewareFailureError, ErrorExclusion, MallDollarVoidError, TokenFailureError } from '../../@shared/error-code-mapping';
import { ErrorService } from '@core/services/application/error.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '../logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UserService } from '../services/auth/user.service';
import { Router } from '@angular/router';
import { LoyaltyService } from '../services/loyalty/loyalty.service';
import * as moment from 'moment';
import { PreserveTabService } from '../services';

const log = new Logger('API Error');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private dialogService: DialogService,
    private userService: UserService,
    private router: Router,
    private loyaltyService: LoyaltyService,
    private preserveTabService: PreserveTabService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error, request)));
  }

  // Customize the default error handler here if needed
  errorHandler(response: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    // Do something with the error
    const errorMetaData = {
      userEmail: this.userService.currentUserInfo.email,
      uri: this.router.url,
      traceId: request.headers.get('X-SPL-TRACEID'),
      errorCode: response.error?.error,
      requestPayload: request.body,
      loyaltyProgramme: this.loyaltyService.currentProgramme,
    };
    log.error(`${response.name} (${response.status}) - ${response.error?.error ?? 'Unknown'}`, response, errorMetaData);
    // Push error to newrelic
    this.errorService.handleApiError(response, request).subscribe((errorResponse) => {
      let errorMessage;
      try {
        const errObj = JSON.parse(errorResponse.errorDetail);
        errorMessage = errObj.error_description;
      } catch (exception) {
        errorMessage = errorResponse.errorDetail;
      }
      if (request.url.includes('guest/contactlessCarParking/realTimeParking')) {
        return throwError({ response: HttpErrorResponse, request });
      }
      if (!Object.values(ErrorExclusion).includes(errorResponse.errorCode)) {
        const malldollarInfo = JSON.parse(localStorage.getItem('MEMBER_GUESTID') as any);
        // Temp mapping
        const dialogData: DialogData = {
          title: 'Error',
          content: errorMessage || 'COMMON.MESSAGE.UNEXPECTED_ERROR',
          hasDebugMessage: true,
          malldollarInfo: Object.values(MallDollarVoidError).includes(errorResponse.errorCode) ? malldollarInfo : null,
          customData: {
            dateTime: moment().format(`YYYY-MM-DD HH:mm:ss`),
            traceId: request.headers.get('X-SPL-TRACEID'),
          },
          width: '370px',
          yesCallback: (dialogRef) => {
            dialogRef.close();
            if (Object.values(TokenFailureError).includes(errorResponse.errorCode)) {
              this.userService.logout(true);
            }
            if (Object.values(CarParkMiddlewareFailureError).includes(errorResponse.errorCode)) {
              if (this.userService.currentUserRole.toLowerCase() === 'jockey') {
                this.router.navigateByUrl('/admin/members/list');
              } else {
                this.preserveTabService.navigateSamePageTab('/admin/members/view', { tabIndex: 0 });
              }
            }
            this.errorService.onCloseErrorDialog.next(null);
          },
        };
        this.dialogService.showErrorDialog(dialogData).subscribe();
      }
    });
    return throwError({ response: HttpErrorResponse, request });
  }
}
