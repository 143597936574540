import { Component, OnDestroy, OnInit,Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@app/@core';
import { DropdownService, LoyaltyService, FormService} from '@app/@core';
import { SystemCurrencyPipe } from '@app/@shared/pipes';
import { map } from 'rxjs';
import { MerchantPipe } from '../../../../pipes/merchant/merchant.pipe';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'compare-rpt-receipts-dialog-dialog',
  templateUrl: './compare-rpt-receipts-dialog.component.html',
  styleUrls: ['./compare-rpt-receipts-dialog.component.scss'],
})
export class CompareRptReceiptsDialogComponent implements OnInit, OnDestroy {
  paymentFormGroup = new UntypedFormGroup({});
  currentFormGroup = new UntypedFormGroup({});
  formParams: any = {};
  currentFormParams: any = {};
  options: FormlyFormOptions = {};
  currentOptions: FormlyFormOptions = {};
  filterFields: FormlyFieldConfig[] = [
    {
      key: 'tenantId',
      type: 'input',
      props: {
        label: 'Tenant Name',
      },
    },
    {
      key: 'transactionDate',
      type: 'input',
      props: {
        label: 'Transaction Date',
      }
    },
    {
      key: 'transactionTime',
      type: 'input',
      props: {
        label: 'Transaction Time',
      }
    },
    {
      key: 'transactionAmount',
      type: 'input',
      templateOptions: {
        label: 'Transaction Amount',
      }
    },
    {
      key: 'receiptAmount',
      type: 'input',
      props: {
        label: 'Electronic Receipt Amount',
      },
    },
    {
      key: 'paymentMethod',
      type: 'select',
      templateOptions: {
        label: 'Payment Method',
        options: this.dropdownService.getDropdown().pipe(map((res) => res['RECEIPT_TRANSACTION']['PAYMENT_METHOD'])),
        valueProp: 'value',
        labelProp: 'label',
      },
    },

    {
      key: 'excludedAmount',
      type: 'input',
      props: {
        label: 'Excluded Amount',
      },
    },
    {
      key: 'rptMallDollarDiscountedAmount',
      type: 'input',
      props: {
        label: 'Actualize Amount',
      },
    },


    {
      key: 'invoiceNumbers',
      type: 'multiple-input',
      
      props: {
        label: 'Invoice Numbers',
        className: 'compare-invoiceNumber'
      }
    },
    {
      key: 'rptAcquireReferenceNos',
      type: 'multiple-input',
      props: {
        label: 'RPT Acquirer Ref No(s)',
      }
    },
    {
      key: 'remarks',
      type: 'input',
      props: {
        label: 'Transaction Remarks',
      }
    }
  ];
  currentFilterFields: FormlyFieldConfig[] = [
    {
      key: 'tenantId',
      type: 'input',
      props: {
        label: 'Tenant Name',
      },
    },
    {
      key: 'transactionDate',
      type: 'input',
      props: {
        label: 'Transaction Date',
      }
    },
    {
      key: 'transactionTime',
      type: 'input',
      props: {
        label: 'Transaction Time',
      }
    },
    {
      key: 'transactionAmount',
      type: 'input',
      templateOptions: {
        label: 'Transaction Amount',
      }
    },
    {
      key: 'receiptAmount',
      type: 'input',
      props: {
        label: 'Electronic Receipt Amount',
      },
    },
    {
      key: 'paymentMethod',
      type: 'select',
      templateOptions: {
        label: 'Payment Method',
        options: this.dropdownService.getDropdown().pipe(map((res) => res['RECEIPT_TRANSACTION']['PAYMENT_METHOD'])),
        valueProp: 'value',
        labelProp: 'label',
      },
    },

    {
      key: 'excludedAmount',
      type: 'input',
      props: {
        label: 'Excluded Amount',
      },
    },
    {
      key: 'rptMallDollarDiscountedAmount',
      type: 'input',
      props: {
        label: 'Actualize Amount',
      },
    },

    {
      key: 'invoiceNumbers',
      type: 'multiple-input',
      props: {
        label: 'Invoice Numbers',
        placeholder:'N/A',
      }
    },
    {
      key: 'rptAcquireReferenceNos',
      type: 'multiple-input',
      props: {
        label: 'RPT Acquirer Ref No(s)',
      }
    },
    {
      key: 'remarks',
      type: 'input',
      props: {
        label: 'Transaction Remarks',
      }
    }
  ];
  currentValue:any = null;
  backValue:any = null;
  constructor(
    protected systemCurrencyPipe: SystemCurrencyPipe,
    private dropdownService: DropdownService,
    private loyaltyService: LoyaltyService, 
    private formService: FormService,
    public merchantPipe: MerchantPipe,
    public dialogRef: MatDialogRef<CompareRptReceiptsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    data.yesCallback = data.yesCallback || this.noop;
    data.noCallback = data.noCallback || this.noop;

    this.currentValue = data.customData.currentValue;
    const backValue = data.customData.backValue;
    this.backValue = data.customData.backValue;

  }
  noop = () => {};
  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
    this.paymentFormGroup.disable();
    this.currentFormGroup.disable();
    this.paymentFormGroup.patchValue(this.backValue)
    this.currentFormGroup.patchValue(this.currentValue);

    const num = Number(this.backValue.mallDollarTotal)+Number(this.backValue.evoucherTotal)
    this.paymentFormGroup.get('rptMallDollarDiscountedAmount')?.setValue(this.systemCurrencyPipe.transform(num));
    this.paymentFormGroup.get('excludedAmount')?.setValue(this.systemCurrencyPipe.transform(this.backValue.excludedAmount));


    this.merchantPipe.transform(this.currentValue.tenantId).subscribe((res:any) => {
      this.currentFormGroup.get('tenantId')?.setValue(res)
    })
    this.merchantPipe.transform(this.backValue.tenantId).subscribe((res:any) => {
      this.paymentFormGroup.get('tenantId')?.setValue(res)
    })
    this.setFormat(['receiptAmount', 'transactionAmount'],'paymentFormGroup',this.backValue)
    this.setFormat(['receiptAmount', 'transactionAmount'],'currentFormGroup',this.currentValue)
    this.paymentFormGroup.get('remarks')?.setValue('N/A');
    this.paymentFormGroup.get('invoiceNumbers')?.setValue(['N/A']);
    const rptAcquireReferenceNos = this.backValue?.payments.map((item:any) => item.acquirerRefNo)
    this.paymentFormGroup.get('rptAcquireReferenceNos')?.setValue(rptAcquireReferenceNos);

    this.currentFormGroup.get('rptMallDollarDiscountedAmount')?.setValue('N/A');
    this.currentFormGroup.get('excludedAmount')?.setValue('N/A')
  }
  setFormat(value:any[],formName:any, formValue:any) {
    value.forEach((item:any) => {
      this[formName].get(item)?.setValue(this.systemCurrencyPipe.transform(formValue[item]))
    })
  }
  ngOnDestroy(): void {
    
  }
}
