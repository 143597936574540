import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LoyaltyService } from '../loyalty';
import { SHORTCUT_ITEM } from './shortcut.config';

@Injectable({
  providedIn: 'root',
})
export class ShortcutService {
  constructor(private loyaltyService: LoyaltyService) {}

  getListItems() {
    return SHORTCUT_ITEM(this.loyaltyService.currentProgramme.registrationLink || '', this.loyaltyService.currentProgramme.businessUnit);
  }
}
