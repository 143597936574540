<ng-container autoFocus>
  <div class="dialog-container">
    <h3 class="dialog-title">Revert Reason</h3>
    <form [formGroup]="revertForm">
      <mat-form-field class="full-width">
        <mat-label style="color: #1b4266; font-weight: inherit">Select Revert Reason(s)</mat-label>
        <mat-select formControlName="reasons" multiple>
          <mat-option *ngFor="let item of revertReasionList" [value]="item.value">{{ item.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!revertForm.get('reasons').value">This is a required field</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label style="color: #1b4266; font-weight: inherit">Remarks</mat-label>
        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="" />
      </mat-form-field>
      <div class="form-stick-bottom-bar revert-bottom-bar">
        <c-form-button buttonType="stroke" label="cancel" class="grey" [upperCaseLabel]="true" (click)="onCancelClick()"> </c-form-button>
        <c-form-button label="submit" [upperCaseLabel]="true" (click)="onClickSubmit()"></c-form-button>
      </div>
    </form>
  </div>
</ng-container>
