import { environment } from '@env/environment';

export const SHORTCUT_ITEM = (link: string | null, bu: any) => {
  let data:any[]= [ {
    title: 'MENU.LABEL.MEMBER_PROFILE',
    icon: 'domain',
    link: '/admin/members/list',
  }]
  if(bu != "CP") {
    data.push({
      title: 'MENU.LABEL.MEMBER_REGISTRATION',
      icon: 'account_box',
      // link: '/admin/members/create',
      action: () => {
        window.open(link || environment.MEMBER_REGISTRATION_LINK);
      },
    },)
  }
  return data;
};
