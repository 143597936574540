import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { GlobalErrorHandler } from './@core/basic-error-handler';

// App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Shells
import { AdminShellModule } from '@app/shells/admin-shell/admin-shell.module';
import { ConfigShellModule } from '@app/shells/config-shell/config-shell.module';
import { CsShellModule } from '@app/shells/cs-shell/cs-shell.module';

// Components
import { ForbiddenComponent } from './pages/system-error-pages/forbidden/forbidden.component';

// Services
import { LoyaltyService, MetadataResolver, UserService, DropdownResolver, BreadcrumbService, DebugService } from '@core/services';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { I18nService } from './i18n/i18n.service';

// Import library modules
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

// Formly import
import { DateRangePickerComponent } from './@shared/components/form-field/date-range-picker/date-range-picker.component';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MaskInputComponent } from './@shared/components/form-field/mask-input/mask-input.component';
import { PointRangeFormFieldComponent } from './@shared/components/form-field/point-range-form-field/point-range-form-field.component';
import { formlyValidators, formlyValidationMessage, showError } from './@shared/components/form-field/formly-form-field-validation.constants';
import { SelectAllFormFieldComponent } from './@shared/components/form-field/select-all-form-field/select-all-form-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectFieldComponent } from './@shared/components/form-field/ng-select-field/ng-select-field.component';
import { MatFormFieldWrapperComponent } from './@shared/components/form-field/mat-form-field-wrapper/mat-form-field-wrapper.component';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { YearMonthPickerComponent } from './@shared/components/form-field/year-month-picker/year-month-picker.component';
import { MultipleInputComponent } from './@shared/components/form-field/multiple-input-field/multiple-input-field.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

export function initializeUserConfig(userService: UserService) {
  return () => {
    return userService.runInitialLoginSequence();
  };
}

export function initializeTranslateService(i18nService: I18nService) {
  return () => {
    return i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
  };
}

@NgModule({
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    SharedModule,
    AdminShellModule,
    ConfigShellModule,
    CsShellModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    LoadingBarModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validators: formlyValidators,
      validationMessages: formlyValidationMessage,
      types: [
        {
          name: 'date-range',
          component: DateRangePickerComponent,
        },
        {
          name: 'mask-input',
          component: MaskInputComponent,
        },
        {
          name: 'point-range',
          component: PointRangeFormFieldComponent,
        },
        {
          name: 'select-all',
          component: SelectAllFormFieldComponent,
        },
        {
          name: 'ng-select',
          component: NgSelectFieldComponent,
        },
        {
          name: 'year-month',
          component: YearMonthPickerComponent,
        },
        {
          name:'multiple-input',
          component: MultipleInputComponent,
        }
      ],
      wrappers: [
        {
          name: 'mat-form-field',
          component: MatFormFieldWrapperComponent,
        },
      ],
      extras: { lazyRender: true, showError },
    }),
    FormlyMaterialModule,
    FontAwesomeModule,
    FormlyMatToggleModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
  ],
  declarations: [AppComponent, ForbiddenComponent],
  providers: [
    BreadcrumbService,
    CdkColumnDef,
    MetadataResolver,
    DropdownResolver,
    DebugService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // Init user info and program info
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslateService,
      deps: [I18nService, Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserConfig,
      deps: [UserService, LoyaltyService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Console log for QA Purpose
    const messageStyle = 'font-size: 24px; color: black; background: yellow;';
    if (environment.production) {
      console.log(`%c${environment.version}%s `, messageStyle, 'Developer Console');
    } else {
      console.log(`%c${environment.version}%s `, messageStyle, 'Nightly Build - Be warned: This build can be unstable.');
    }
  }
}
