import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, publishReplay, refCount, distinctUntilChanged } from 'rxjs/operators';
import { LoyaltyProgramme, LoyaltyProgrammeTier, LoyaltyProgrammeTierRule } from '@core/models/loyalty';
import { ApiResponse } from '@core/models/application/api-response';
import { HttpClient } from '@angular/common/http';
import { DropdownService } from '@core/services/application/generic-category';
import { CampaignService } from '@app/@core/services/campaigns/campaign.service';
import { TenantService } from '@app/@core/services/tenant.service';
import { GiftService } from '@app/@core';

export type LpCheckRecordType = 'GUEST' | 'CAMPAIGN';
interface RecordTypeResponse extends ApiResponse {
  data: {
    businessUnit: string;
    id: string;
    loyaltyProgram: string;
    type: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  public currentProgrammeSubject = new BehaviorSubject<LoyaltyProgramme>({} as LoyaltyProgramme);
  public currentProgramme$ = this.currentProgrammeSubject.asObservable().pipe(distinctUntilChanged());

  public loyaltyProgrammesSubject = new BehaviorSubject<LoyaltyProgramme[]>([] as LoyaltyProgramme[]);
  public loyaltyProgrammes$ = this.loyaltyProgrammesSubject.asObservable().pipe(distinctUntilChanged());

  public tierListObservable: Observable<any> = null;

  private tenantService: TenantService;
  private giftService: GiftService;

  constructor(private httpService: HttpClient, private dropdownService: DropdownService, private campaignService: CampaignService, private injector: Injector) {}

  initProgrammeList(user?: string): Promise<any> {
    return this.refreshProgrammeList(user).toPromise();
  }

  refreshProgrammeList(user?: string) {
    const params: {
      orderBy: string;
      order: string;
      user?: string;
    } = { orderBy: 'name', order: 'ASC' };

    return this.getAllLoyaltyProgrammes(params).pipe(
      map((programmes) => {
        this.loyaltyProgrammesSubject.next(programmes.content);

        const currentProgramme = programmes.content.find((programme: LoyaltyProgramme) => programme.id === this.currentProgramme.id);
        this.currentProgrammeSubject.next(currentProgramme);
      })
    );
  }

  getGlobalLoyaltyProgrammes(params?: any): Observable<any> {
    return this.httpService.get(`/admin/loyaltyProgram`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<any>;
  }

  getLoyaltyProgrammes(params?: any): Observable<any> {
    return this.httpService.get(`/admin/loyaltyProgram?businessUnit=${this.currentProgramme.businessUnit}`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<any>;
  }

  getAllLoyaltyProgrammes(params?: any): Observable<any> {
    return this.httpService.get(`/admin/loyaltyProgram`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      }),
      publishReplay(1),
      refCount()
    ) as Observable<any>;
  }

  getTierList(loyaltyProgram: string): Observable<any> {
    return this.httpService.get(`/tier?businessUnit=${this.currentProgramme.businessUnit}&loyaltyProgram=${loyaltyProgram}`).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<LoyaltyProgrammeTier[]>;
  }

  getTierAndRules(loyaltyProgram: string, businessUnit?: string, tier?: string): Observable<LoyaltyProgrammeTierRule[]> {
    let url = `/tier/tierAndTierRules?businessUnit=${businessUnit || this.currentProgramme.businessUnit}&loyaltyProgram=${loyaltyProgram}`;
    url = tier ? url + `&tier=${tier}` : url;
    return this.httpService.get(url).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<LoyaltyProgrammeTierRule[]>;
  }

  saveTierAndRules(saveObj: LoyaltyProgrammeTierRule): Observable<any> {
    return this.httpService.put(`/tier/tierAndTierRules`, saveObj).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<any>;
  }

  createLoyaltyProgramme(saveObj: LoyaltyProgramme): Observable<any> {
    return this.httpService.post(`/admin/loyaltyProgram`, saveObj).pipe(
      map((res: ApiResponse) => {
        // this.initProgrammeList();
        return res.data;
      })
    ) as Observable<any>;
  }

  updateLoyaltyProgramme(saveObj: LoyaltyProgramme): Observable<any> {
    return this.httpService.put(`/admin/loyaltyProgram`, saveObj).pipe(
      map((res: ApiResponse) => {
        // this.initProgrammeList();
        return res.data;
      })
    ) as Observable<any>;
  }

  switchLoyaltyProgramme(bu: string, lp: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const targetProgramme = this.loyaltyProgrammesSubject.value.find((programme) => {
        return programme.businessUnit === bu && programme.code === lp;
      });
      localStorage.setItem('CURRENT_LOYALTY_PROGRAMME', JSON.stringify(targetProgramme));
      this.currentProgrammeSubject.next(targetProgramme);
      this.tierListObservable = null;
      this.dropdownService.dropdown = null;
      this.campaignService.queryBuilderConfig$ = null;

      // this.tenantService = this.injector.get(TenantService);
      // this.tenantService.clearAllCaches();

      this.injector.get(TenantService)?.clearAllCaches();
      this.injector.get(GiftService)?.clearAllCaches();

      resolve(targetProgramme);
    });
  }

  get currentProgramme(): LoyaltyProgramme {
    return this.currentProgrammeSubject.value;
  }

  get isCG() {
    return this.currentProgramme?.businessUnit == 'CG'
  }

  get allLoyaltyProgrammes(): LoyaltyProgramme[] {
    return this.loyaltyProgrammesSubject.value;
  }

  getTierOptions(): Observable<LoyaltyProgrammeTier[]> {
    if (!this.tierListObservable) {
      this.tierListObservable = this.httpService.get(`/tier?businessUnit=${this.currentProgramme.businessUnit}&loyaltyProgram=${this.currentProgramme.code}`).pipe(
        map((res: ApiResponse) => {
          return res.data.content;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.tierListObservable;
  }

  getRecordLoyaltyProgramme(type: LpCheckRecordType, id: string) {
    return this.httpService.get(`/admin/businessUnit/retrieveByTypeAndId`, { params: { type, id } }).pipe(
      map((res: RecordTypeResponse) => {
        return res.data;
      })
    );
  }
}
