import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { DropdownService } from '../../../../@core/services/application/generic-category/dropdown.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoyaltyService } from '@core/services/loyalty';

@Component({
  selector: 'app-reimbursement-revert-dialog',
  templateUrl: './reimbursement-revert-dialog.component.html',
  styleUrls: ['./reimbursement-revert-dialog.component.scss'],
})
export class RevertReimbursementDialogComponent implements OnInit {
  revertForm: UntypedFormGroup;
  revertReasionList:any[] = [];
  afterSave: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dropdownService: DropdownService,
    public dialogRef: MatDialogRef<RevertReimbursementDialogComponent>,
    private fb: UntypedFormBuilder,
    public loyaltyService: LoyaltyService
  ) {
    if(this.data?.isPaymentTerminal) {
      this.revertReasionList = data.dropdownData?.filter((item:any) => item.value != "WRONG_INVOICE_AMOUNT")
    }else {
      this.revertReasionList = data.dropdownData
    }
    
    const reasons =
      this.loyaltyService.currentProgramme.businessUnit === 'PP'
        ? `Please re-submit within ${this.loyaltyService.currentProgramme?.reimbursementMerchantTransactionResubmissionExpiryDays} Days to avoid Reimbursement delay.`
        : null;
    this.revertForm = this.fb.group({
      reasons: [null, [Validators.required]],
      remarks: [reasons],
    });
  }
  ngOnInit() {}
  onCancelClick() {
    this.dialogRef.close();
  }
  onClickSubmit() {
    this.revertForm.markAllAsTouched();
    this.revertForm.updateValueAndValidity();
    if (this.revertForm.valid) {
      this.afterSave.emit({
        remark: this.revertForm.get('remarks')?.value,
        reasons: this.revertForm.get('reasons')?.value,
      });
      this.dialogRef.close();
    }
  }
}
