export abstract class ControlKeyMappingConstants {
  public static CONTROL_KEY_MAPPING = {
    LIST_MEMBER: {
      firstName: { label: 'English First Name', isFreeTextSearchable: true },
      lastName: { label: 'English Last Name', isFreeTextSearchable: true },
      nickName: { label: 'Nick Name', isFreeTextSearchable: true },
      accountStatus: { label: 'Member Status' },
      tier: { label: 'Tier' },
      tierName: { label: 'Tier Name', isFreeTextSearchable: false },
      hasActiveAssociation: {
        label: 'Show associated member',
        displayValueHandling: (param: string) => {
          if (param === 'true') return null;
          return param;
        },
      },
      blacklist: {
        label: 'Blacklisted',
        displayValueHandling: (param: string) => {
          if (param === 'true') return 'Yes';
          return 'No';
        },
      },
      carplate: { label: 'Car Plate', isFreeTextSearchable: false },
    },
    LIST_TRANSACTION: (depositTransactionOptions: any[]) => {
      return {
        guestId: { label: 'Membership Number', isFreeTextSearchable: true },
        invoiceNumbers: { label: 'Invoice Number', isFreeTextSearchable: true },
        rptAcquireReferenceNos: { label: 'RPT Acquirer Ref No', isFreeTextSearchable: false },
        createdBy: { label: 'Created By (Email/Member ID)', isFreeTextSearchable: true },
        transactionUploadSource: { label: 'Upload Channel', isFreeTextSearchable: true },
        lookupTenantName: { label: 'Tenant' },
        transactionAmount: { label: 'Transaction Amount', isFreeTextSearchable: true },
        referredBy: { label: 'Referred By', isFreeTextSearchable: true },
        receiptAmount: { label: 'Electronic Receipt Amount', isFreeTextSearchable: true },
        depositTransaction: { label: 'Deposit', isFreeTextSearchable: true, options: depositTransactionOptions },
        verifyFailureReasons: { label: 'Verification Failure Reason', isFreeTextSearchable: true },
        id: { label: 'Transaction ID', isFreeTextSearchable: true },
        status: { label: 'Status' },
        tenantId: { label: 'Tenant', isFreeTextSearchable: true },
        membershipTier: { label: 'Member Tier' },
        paymentMethod: { label: 'Payment Method' },
        createdDateStart: { label: 'Uploaded Start Date' },
        createdDateEnd: { label: 'Uploaded End Date' },
        verifyStatus: { label: 'Receipt Verification' },
        tierName: { label: 'Member Tier' },
        transactionDateStart: { label: 'Transaction Date Start' },
        transactionDateEnd: { label: 'Transaction Date End' },
        creditToAssociateMember: {
          label: 'Show receipt(s) credit to associated member',
          displayValueHandling: (param: string) => {
            if (param === 'true') return null;
            return param;
          },
        },
        transactionAmountRange: {
          label: 'Transaction Amount',
          displayValueHandling: (param: string) => {
            const paramObj = JSON.parse(param);
            return paramObj.transactionAmountTo
              ? `${Number(paramObj.transactionAmountFrom).toLocaleString()} - ${Number(paramObj.transactionAmountTo).toLocaleString()}`
              : `${Number(paramObj.transactionAmountFrom).toLocaleString()}`;
          },
        },
        receiptAmountRange: {
          label: 'Receipt Amount',
          displayValueHandling: (param: string) => {
            const paramObj = JSON.parse(param);
            return paramObj.receiptAmountTo ? `${Number(paramObj.receiptAmountFrom).toLocaleString()} - ${Number(paramObj.receiptAmountTo).toLocaleString()}` : `${Number(paramObj.receiptAmountFrom).toLocaleString()}`;
          },
        },
      };
    },
    LIST_REQUEST: {
      approvalRequestType: { label: 'Request Type', isFreeTextSearchable: true },
      firstName: { label: 'Member First Name', isFreeTextSearchable: true },
      lastName: { label: 'Member Last Name', isFreeTextSearchable: true },
      id: { label: 'Request ID', isFreeTextSearchable: true },
      requestReason: { label: 'Request Reason', isFreeTextSearchable: true },
      status: { label: 'Request Status', isFreeTextSearchable: true },
      tier: { label: 'Membership Tier', isFreeTextSearchable: true },
      guestId: { label: 'Membership No.', isFreeTextSearchable: true },
      submittedBy: { label: 'Submitted By', isFreeTextSearchable: true },
      submittedDateStart: { label: 'From' },
      submittedDateEnd: { label: 'To' },
      enrouteRequestFiltering: { label: 'Enroute Request Filtering' },
    },
    LIST_INVITATION_LINK: {
      email: { label: 'Email Address', isFreeTextSearchable: true },
      firstName: { label: 'English First Name', isFreeTextSearchable: true },
      lastName: { label: 'English Last Name', isFreeTextSearchable: true },
      tier: { label: 'Member Tier', isFreeTextSearchable: true },
      invitationSourceName: { label: 'Invitation Source', isFreeTextSearchable: true },
      actionBy: { label: 'Actioned By', isFreeTextSearchable: true },
      referredBy: { label: 'Referred By', isFreeTextSearchable: true },
      status: { label: 'Status' },
    },
    LIST_CAMPAIGN: {
      name: { label: 'Campaign Name', isFreeTextSearchable: true },
      code: { label: 'Campaign Code', isFreeTextSearchable: true },
      startDateAfter: { label: 'Start Date' },
      endDateBefore: { label: 'End Date' },
    },
    LIST_GIFT: {
      fullName: { label: 'Gift Full Name', isFreeTextSearchable: true },
      code: { label: 'Gift Code', isFreeTextSearchable: true },
      type: { label: 'Gift Type', isFreeTextSearchable: true },
      providerName: { label: 'Gift Provider', isFreeTextSearchable: true },
      description: { label: 'Description', isFreeTextSearchable: true },
      createdDateStart: { label: 'Created Start Date' },
      createdDateEnd: { label: 'Created End Date' },
    },
    LIST_REDEMPTION_ACTIVITIES: {
      giftName: { label: 'Gift Name', isFreeTextSearchable: true },
      promotionType: { label: 'Gift Code', isFreeTextSearchable: true },
      redemptionChannel: { label: 'Redemption Channel', isFreeTextSearchable: true },
      actualizationLocation: { label: 'Actualization Location', isFreeTextSearchable: true },
      actualizedBy: { label: 'Actualized By', isFreeTextSearchable: true },
      redeemedBy: { label: 'Redeemed By' },
      redemptionDateStart: { label: 'Redemption Start Date' },
      redemptionDateEnd: { label: 'Redemption End Date' },
      actualizedDateStart: { label: 'Actualize Start Date' },
      actualizedDateEnd: { label: 'Actualize End Date' },
      transactionNumber: { label: 'Transaction Number' },
      status: { label: 'Status' },
      promotionName: { label: 'Promotion Name' },
      giftType: { label: 'Gift Type' },
      receiptTransactionId: { label: 'Receipt Transaction ID' },
      actualizationChannel: {label: 'Actualization Channel'}
    },
    LIST_ASSOCIATED_MEMBER: {
      firstName: { label: 'English First Name', isFreeTextSearchable: true },
      lastName: { label: 'English Last Name', isFreeTextSearchable: true },
      nickName: { label: 'Nick Name', isFreeTextSearchable: true },
      tierName: { label: 'Tier Name' },
    },
    LIST_DEPOSIT_TRANSACTION: {
      id: { label: 'Transaction ID', isFreeTextSearchable: true },
      invoiceNumbers: { label: 'Invoice Number', isFreeTextSearchable: true },
      transactionUploadSource: { label: 'Upload Channel', isFreeTextSearchable: true },
      tenantId: { label: 'Tenant', isFreeTextSearchable: true },
      paymentMethod: { label: 'Payment Method' },
      createdDateStart: { label: 'Uploaded Start Date' },
      createdDateEnd: { label: 'Uploaded End Date' },
      transactionDateStart: { label: 'Transaction Date Start' },
      transactionDateEnd: { label: 'Transaction Date End' },
      transactionAmountRange: {
        label: 'Transaction Amount',
        displayValueHandling: (param: string) => {
          const paramObj = JSON.parse(param);
          return paramObj.transactionAmountTo
            ? `${Number(paramObj.transactionAmountFrom).toLocaleString()} - ${Number(paramObj.transactionAmountTo).toLocaleString()}`
            : `${Number(paramObj.transactionAmountFrom).toLocaleString()}`;
        },
      },
      receiptAmountRange: {
        label: 'Receipt Amount',
        displayValueHandling: (param: string) => {
          const paramObj = JSON.parse(param);
          return paramObj.receiptAmountTo ? `${Number(paramObj.receiptAmountFrom).toLocaleString()} - ${Number(paramObj.receiptAmountTo).toLocaleString()}` : `${Number(paramObj.receiptAmountFrom).toLocaleString()}`;
        },
      },
    },
    LIST_REIMBURSEMENT_SUMMARY: {
      reimbursementSummaryId: { label: 'Reimbursement Summary ID', isFreeTextSearchable: true },
      merchantIds: {
        label: 'Tenant',
        mutipleDisplayValueHandling: (options: any[], param: string, key: string = 'id', value: string = 'value') => {
          let vals = param.split(',');
          let labels: string[] = [];
          vals.forEach((val) => {
            labels.push(options[val]);
          });
          return labels.join(',');
        },
      },
      merchantTransactionType: { label: 'Reimbursement Type' },
      transactionDateFrom: { label: 'Month From' },
      transactionDateTo: { label: 'Month To' },
      endorsementId: { label: 'Endorsement Summary ID' },
      reimbursementSummaryPmsStatus: { label: 'PMP Status' },
    },
    LIST_REIMBURSEMENT_TRANSACTION: {
      memberId: { label: 'Membership ID', isFreeTextSearchable: true },
      invoiceNumber: { label: 'Invoice Number', isFreeTextSearchable: true },
      rptAcquireReferenceNos: { label: 'RPT Transaction ID', isFreeTextSearchable: false },
      
      tenantId: { label: 'Tenant' },
      status: { label: 'Submission Status' },

      transactionDateFrom: { label: 'Start Date' },
      transactionDateTo: { label: 'End Date' },
      actualizationTxn: { label: 'Actualization Txn No.' },
      duplicateFlag: { label: 'Duplicate Invoice Number Flag' },
      endorsementId: { label: 'Endorsement Summary ID' },
    },
    LIST_TENANT: {
      eligibleToEarnPoints: { label: 'Eligible to Earn Point'},
      eligibleToSelfUploadReceipts: { label: 'Eligible for Receipt self-uploading'},
      enableAutoApproveReceiptsForTouchpoint: { label: 'Enable Receipt instant earn (App Upload)'},
      usingPaymentTerminal: { label: 'Using Payment Terminal'},
      enableEvoucher: { label: 'eVoucher Merchant'},
      enableMallDollar: { label: 'Accept Mall Dollar'},
      masterMerchantId: {
        label: 'Master Merchant',
        mutipleDisplayValueHandling: (options: any[], param: string, key: string = 'id', value: string = 'value') => {
          let vals = param.split(',');
          let labels: string[] = [];
          vals.forEach((val) => {
            labels.push(options[val]);
          });
          return labels.join(',');
        },
      },
      consignment: { label: 'Consignment'},
    }
  };
}
